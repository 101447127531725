/* Globals */

:root {
  /* Primary CityCoins Branding */
  --brand-primary: #3059d9;
  --brand-accent: #f7c600;
  --brand-hover: #000;
  --brand-text: #000;
  --brand-layer-2: #c4c6c8;
  --brand-layer-1: #d1d3d4;
  --brand-layer-0: #ebeced;
  --brand-background: #fff;
  /* Triadic and Complementary Variations */
  --brand-info: #30aed9;
  --brand-success: #59d930;
  --brand-warning: #d9b030;
  --brand-danger: #d93059;
}

:root.dark-mode {
  /* Primary CityCoins Branding */
  --brand-primary: #3059d9;
  --brand-accent: #f7c600DD;
  --brand-hover: #3059d999;
  --brand-text: #d1d3d4;
  --brand-layer-2: #505456;
  --brand-layer-1: #3d4042;
  --brand-background: #2a2c2d;
  /* Triadic and Complementary Variations */
  --brand-info: #30aed9DD;
  --brand-success: #59d930DD;
  --brand-warning: #d9b030DD;
  --brand-danger: #d93059DD;
}

html,
body {
  background-color: var(--brand-background);
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

a {
  color: var(--brand-primary);
}

inspect {
  border: thin solid var(--brand-danger);
}

.Landing {
  background-color: var(--brand-background);
}

.Landing .jumbotron {
  opacity: 0.92;
  background-color: var(--brand-background);
}

/* Bootstrap Overrides */

.bg-primary {
  background-color: var(--brand-primary) !important;
}

.bg-secondary {
  background-color: var(--brand-layer-0) !important;
  color: var(--brand-text);
}

.border-info {
  border-color: var(--brand-primary) !important;
}

.btn-primary {
  background-color: var(--brand-primary);
  border-color: var(--brand-primary);
}

.btn-outline-primary {
  border-color: var(--brand-primary);
  color: var(--brand-primary);
}

.btn-outline-white {
  border-color: var(--brand-background);
  color: var(--brand-background);
}

.btn-outline-dark {
  border-color: var(--brand-hover);
  color: var(--brand-text);
}

.btn-outline-white:hover,
.btn-outline-white:hover,
.btn-outline-dark:hover {
  background-color: var(--brand-primary);
  color: var(--brand-background);
  border-color: var(--brand-primary);
}

.btn-nav {
  background-color: var(--brand-layer-1);
  color: var(--brand-text);
}

.btn-nav:hover {
  background-color: var(--brand-background);
}

.btn-primary:hover {
  background-color: var(--brand-hover);
}

.navbar-dark,
.bg-footer {
  background-color: var(--brand-primary);
}

.bg-footer a {
  color: var(--brand-background);
  text-decoration: underline;
}

/* Navigation */

.cc-divider {
  background-color: var(--brand-hover);
  margin: 0.5rem 0;
}

.cc-divider-alt {
  background-color: var(--brand-primary);
  margin: 0.5rem 0;
}

.nav-item {
  border: 1px solid transparent;
  opacity: 0.5;
  transition: opacity 0.5s ease-in,
              border 0.5s ease-out;
}

.nav-item-title {
  opacity: 1;
  border-bottom: none !important;
}

.nav-item-active {
  border-bottom: 1px solid var(--brand-hover);
  opacity: 1;
}

.nav-item:hover {
  opacity: 1;
}

.nav-logo {
  width: 35px;
  height: 35px;
}

.nav-text {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.nav-bg {
  z-index: -1;
  pointer-events: none;
  position: absolute;
  object-fit: fill;
}

/* Avatar */

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Scaffolding */

.panel-landing {
  max-width: 50em;
  margin: auto;
}

.text-right {
  text-align: right;
}

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css');

/* Adjust sticky top for profile */

.sticky-top-profile {
  top: 115px;
}

/* Positions small indicator circle on the person-circle icon in profile */
.transform-network-indicator {
  left: 14px;
  top: 1.5px;
}
