@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css);
/* Globals */

:root {
  /* Primary CityCoins Branding */
  --brand-primary: #3059d9;
  --brand-accent: #f7c600;
  --brand-hover: #000;
  --brand-text: #000;
  --brand-layer-2: #c4c6c8;
  --brand-layer-1: #d1d3d4;
  --brand-layer-0: #ebeced;
  --brand-background: #fff;
  /* Triadic and Complementary Variations */
  --brand-info: #30aed9;
  --brand-success: #59d930;
  --brand-warning: #d9b030;
  --brand-danger: #d93059;
}

:root.dark-mode {
  /* Primary CityCoins Branding */
  --brand-primary: #3059d9;
  --brand-accent: #f7c600DD;
  --brand-hover: #3059d999;
  --brand-text: #d1d3d4;
  --brand-layer-2: #505456;
  --brand-layer-1: #3d4042;
  --brand-background: #2a2c2d;
  /* Triadic and Complementary Variations */
  --brand-info: #30aed9DD;
  --brand-success: #59d930DD;
  --brand-warning: #d9b030DD;
  --brand-danger: #d93059DD;
}

html,
body {
  background-color: #fff;
  background-color: var(--brand-background);
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

a {
  color: #3059d9;
  color: var(--brand-primary);
}

inspect {
  border: thin solid #d93059;
  border: thin solid var(--brand-danger);
}

.Landing {
  background-color: #fff;
  background-color: var(--brand-background);
}

.Landing .jumbotron {
  opacity: 0.92;
  background-color: #fff;
  background-color: var(--brand-background);
}

/* Bootstrap Overrides */

.bg-primary {
  background-color: #3059d9 !important;
  background-color: var(--brand-primary) !important;
}

.bg-secondary {
  background-color: #ebeced !important;
  background-color: var(--brand-layer-0) !important;
  color: #000;
  color: var(--brand-text);
}

.border-info {
  border-color: #3059d9 !important;
  border-color: var(--brand-primary) !important;
}

.btn-primary {
  background-color: #3059d9;
  background-color: var(--brand-primary);
  border-color: #3059d9;
  border-color: var(--brand-primary);
}

.btn-outline-primary {
  border-color: #3059d9;
  border-color: var(--brand-primary);
  color: #3059d9;
  color: var(--brand-primary);
}

.btn-outline-white {
  border-color: #fff;
  border-color: var(--brand-background);
  color: #fff;
  color: var(--brand-background);
}

.btn-outline-dark {
  border-color: #000;
  border-color: var(--brand-hover);
  color: #000;
  color: var(--brand-text);
}

.btn-outline-white:hover,
.btn-outline-white:hover,
.btn-outline-dark:hover {
  background-color: #3059d9;
  background-color: var(--brand-primary);
  color: #fff;
  color: var(--brand-background);
  border-color: #3059d9;
  border-color: var(--brand-primary);
}

.btn-nav {
  background-color: #d1d3d4;
  background-color: var(--brand-layer-1);
  color: #000;
  color: var(--brand-text);
}

.btn-nav:hover {
  background-color: #fff;
  background-color: var(--brand-background);
}

.btn-primary:hover {
  background-color: #000;
  background-color: var(--brand-hover);
}

.navbar-dark,
.bg-footer {
  background-color: #3059d9;
  background-color: var(--brand-primary);
}

.bg-footer a {
  color: #fff;
  color: var(--brand-background);
  text-decoration: underline;
}

/* Navigation */

.cc-divider {
  background-color: #000;
  background-color: var(--brand-hover);
  margin: 0.5rem 0;
}

.cc-divider-alt {
  background-color: #3059d9;
  background-color: var(--brand-primary);
  margin: 0.5rem 0;
}

.nav-item {
  border: 1px solid transparent;
  opacity: 0.5;
  transition: opacity 0.5s ease-in,
              border 0.5s ease-out;
}

.nav-item-title {
  opacity: 1;
  border-bottom: none !important;
}

.nav-item-active {
  border-bottom: 1px solid #000;
  border-bottom: 1px solid var(--brand-hover);
  opacity: 1;
}

.nav-item:hover {
  opacity: 1;
}

.nav-logo {
  width: 35px;
  height: 35px;
}

.nav-text {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.nav-bg {
  z-index: -1;
  pointer-events: none;
  position: absolute;
  object-fit: fill;
}

/* Avatar */

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Scaffolding */

.panel-landing {
  max-width: 50em;
  margin: auto;
}

.text-right {
  text-align: right;
}

/* Adjust sticky top for profile */

.sticky-top-profile {
  top: 115px;
}

/* Positions small indicator circle on the person-circle icon in profile */
.transform-network-indicator {
  left: 14px;
  top: 1.5px;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group label {
  position: absolute;
  top: 0;
  font-size: 15px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group {
    display: flex;
    flex-direction: column-reverse;
  }

  .form-label-group label {
    position: static;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Bold.dd24d10e.woff2) format('woff2'),
        url(/static/media/Poppins-Bold.a6b628bd.woff) format('woff'),
        url(/static/media/Poppins-Bold.ac5da554.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Light.9bec05ab.woff2) format('woff2'),
        url(/static/media/Poppins-Light.6f5b7a1c.woff) format('woff'),
        url(/static/media/Poppins-Light.970d5ed2.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Regular.ad19729d.woff2) format('woff2'),
        url(/static/media/Poppins-Regular.387f9003.woff) format('woff'),
        url(/static/media/Poppins-Regular.e09d5cf4.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


