@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Light.woff2') format('woff2'),
        url('Poppins-Light.woff') format('woff'),
        url('Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

